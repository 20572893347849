import React, { useState } from "react"
import { Link, graphql, navigate } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Image from "../components/image"
import Helmet from "react-helmet"
import { FaChevronDown } from "react-icons/fa"
import { ShopNameTag, ShopName, ShopNameSelected, ImageFilename} from '../utils/common'
import moment from 'moment'
import { FaCheckSquare } from "react-icons/fa";
import { FaRegSquare } from "react-icons/fa";

const TableMilk = ({ data, location, pageContext: { _shop_id, shop_id, option_id } }) => {
  const [isActive, setIsActive] = useState(false);
  const [selected, setSelected] = useState(shop_id);

	const toggleDropdown = () => { setIsActive(!isActive);};

	const handleItemClick = (_shop_id, shop_id, option_id) => {
    setSelected(shop_id);
    setIsActive(false);
    const url = ShopLink(_shop_id, option_id)
    navigate(url);
  }
	const targets = [
		{size:'can_big',	type:'milk_powder', other:''},
		{size:'can_small',type:'milk_powder', other:''},
		{size:'stick',		type:'milk_powder', other:''},
		{size:'cube',			type:'milk_powder', other:''},
		{size:'refill',		type:'milk_powder', other:''},
	]

	const targets2 = [
		{size:'can',	type:'milk_liquid', other:''},
		{size:'pouch',	type:'milk_liquid', other:''},
		{size:'pack',	type:'milk_liquid', other:''},
	]

	const title = `${ShopNameTag(shop_id)}ミルク単価表`
	let description = `${ShopNameTag(shop_id)}ミルク100mlあたりの価格を比較して種類別に1位から3位までランキング！`

	// 更新日付をGraphQLから直接表示するとUTCとなるため、自前のmoment.jsで表示
	const update = moment().format('YYYY年M月D日')

	// 条件に基づいてitemListをフィルタリングする
	const filteredContentfulItems = data.allContentfulItems.edges.map(edge => {
		// option_idが空文字列でない場合はフィルタリング、空文字列の場合はそのまま
		// const filteredItemList = option_id !== "" 
		// 	? edge.node.itemList.filter(item => !item.txt.includes('らくベビ300') && !item.txt.includes('らくベビ600'))
		// 	: edge.node.itemList;
		let filteredItemList = edge.node.itemList;
		if(option_id === '2'){filteredItemList = edge.node.itemList.filter(item => !item.txt.includes('らくベビ300') && !item.txt.includes('らくベビ600'))} 
		if(option_id === '3'){filteredItemList = edge.node.itemList.filter(item => !item.txt.includes('GOONPLUS2441'))} 
		if(option_id === '4'){filteredItemList = edge.node.itemList.filter(item => !item.txt.includes('らくベビ300') && !item.txt.includes('らくベビ600') && !item.txt.includes('GOONPLUS2441'))} 

		if(shop_id !== 'all'){filteredItemList = filteredItemList.filter(item => item.shopId === shop_id)} 
		
		return {
			...edge,
			node: {
				...edge.node,
				itemList: filteredItemList
			}
		};
	});

  // allContentfulItemsを100ML当たりの価格が安い順に並び替える(Graphqlのソートだと100円以上の場合に並び替えが正しくできないため数値のソートを自前で行う)
	filteredContentfulItems.sort(function(a,b){
		if((!a.node.itemList.length) && (!b.node.itemList.length)){
			return 0
		}
		// itemList無ければ後ろへ並べる
		else if((!a.node.itemList.length) && (b.node.itemList.length)){
			return 1
		}
		else if((a.node.itemList.length) && (!b.node.itemList.length)){
			return -1
		}
		return a.node.itemList[0].per - b.node.itemList[0].per
	})

	// パンくずリストの構造化データ
	const jsonLd = {
		"@context": "https://schema.org",
		"@type": "BreadcrumbList",
		"itemListElement": [{
			"@type": "ListItem",
			"position": 1,
			"name": "トップ",
			"item": "https://hula-la.com/"
		}]
	};

	return (
  <Layout>
		<nav className="breadcrumb is-small" aria-label="breadcrumbs" style={{marginBottom: `0.5rem`, marginLeft: `0.5rem`}}>
			<ul>
				<li><Link to="/">トップ</Link></li>
			</ul>
		</nav>
			<>
				<Helmet>
     			<script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
   			</Helmet>
				{ option_id !== '' && (
	      	<Helmet><meta name="robots" content="noindex" /></Helmet>
	      )}
				<div className="content">
					<h3 className="title is-6" style={{marginBottom: `0.5rem`, marginLeft: `0.5rem`}}>{ShopNameTag(shop_id)}ミルク単価表<small> ({update}更新)</small></h3>
					<div class="container" style={{marginRight: `0.0rem`}}>
						<div class="columns is-mobile">
							<div class="column is-5" style={{paddingTop: `0.8rem`, display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
							{ (shop_id === 'all' || shop_id === 'amazon') && (
              	<>
								{ option_id === '' && (
									<p>
									{/* <Link to={`/milk${_shop_id}2.html`}><span className="icon"><FaRegSquare style={{color: `#EB9DA8`}} /></span><span style={{fontSize:`0.7rem`, color: `#808080`, position: 'relative', top: '-0.2rem'}}>らくベビ使用済</span></Link><br /> */}
									</p>
								)}
								{ option_id === '2' && (
									<p>
									{/* <Link to={`/milk${_shop_id}.html`}><span className="icon"><FaCheckSquare style={{color: `#EB9DA8`}} /></span><span style={{fontSize:`0.7rem`, color: `#808080`, position: 'relative', top: '-0.2rem'}}><strong>らくベビ使用済</strong></span></Link><br /> */}
									</p>
								)}
								{ option_id === '3' && (
									<p>
									{/* <Link to={`/milk${_shop_id}4.html`}><span className="icon"><FaRegSquare style={{color: `#EB9DA8`}} /></span><span style={{fontSize:`0.7rem`, color: `#808080`, position: 'relative', top: '-0.2rem'}}>らくベビ使用済</span></Link><br /> */}
									</p>
								)}
								{ option_id === '4' && (
									<p>
									{/* <Link to={`/milk${_shop_id}3.html`}><span className="icon"><FaCheckSquare style={{color: `#EB9DA8`}} /></span><span style={{fontSize:`0.7rem`, color: `#808080`, position: 'relative', top: '-0.2rem'}}><strong>らくベビ使用済</strong></span></Link><br /> */}
									</p>
								)}
								</>
							)}
							</div>
							<div class="column is-4" style={{paddingLeft: `0.0rem`, paddingBottom: `0.9rem`}}>
								<div className="has-text-centered" style={{paddingTop: `0.3rem`, paddingBottom: `0.0rem`}}>
									<p style={{fontSize:`0.7rem`, marginTop: `0.0rem`}}><a href={`https://hula-la.com/campaign${_shop_id}.html`} target='_blank' style={{color: `#0783c2`}}>{ShopNameTag(shop_id, false)} キャンペーン情報</a></p>
								</div>              
							</div>
							<div class="column is-3" style={{paddingBottom: `0.9rem`}}>
								<div class="is-flex is-justify-content-flex-end"style={{marginBottom: `0rem`}}>
									<div className={`dropdown ${isActive ? 'is-active' : ''}`}>
										<div class="dropdown-trigger">
											<button onClick={toggleDropdown} className="button" aria-haspopup="true" aria-controls="dropdown-menu" style={{fontSize:`0.6rem`}}>
												<span>{ShopNameSelected(selected)}</span>
												<span class="icon is-small">
													<FaChevronDown color="#EB9DA8" size="1.0em" />
												</span>
											</button>
										</div>
										<div class="dropdown-menu" id="dropdown-menu" role="menu">
											<div class="dropdown-content">
												<a href="#" class={`dropdown-item ${selected === 'all' ? 'is-active' : ''}`} onClick={() => handleItemClick('', 'all', option_id)} style={{fontSize:`0.6rem`}}>
													{ShopName('all')}</a>
												<a href="#" class={`dropdown-item ${selected === 'amazon' ? 'is-active' : ''}`} onClick={() => handleItemClick('_amazon', 'amazon', option_id)} style={{fontSize:`0.6rem`}}>
													{ShopName('amazon')}</a>
												<a href="#" class={`dropdown-item ${selected === 'rakuten' ? 'is-active' : ''}`} onClick={() => handleItemClick('_rakuten', 'rakuten', option_id)} style={{fontSize:`0.6rem`}}>
													{ShopName('rakuten')}</a>
												<a href="#" class={`dropdown-item ${selected === 'yahoo' ? 'is-active' : ''}`} onClick={() => handleItemClick('_yahoo', 'yahoo', option_id)} style={{fontSize:`0.6rem`}}>
													{ShopName('yahoo')}</a>
												<a href="#" class={`dropdown-item ${selected === 'lohaco' ? 'is-active' : ''}`} onClick={() => handleItemClick('_lohaco', 'lohaco', option_id)} style={{fontSize:`0.6rem`}}>
													{ShopName('lohaco')}</a>
												<a href="#" class={`dropdown-item ${selected === 'aupay' ? 'is-active' : ''}`} onClick={() => handleItemClick('_aupay', 'aupay', option_id)} style={{fontSize:`0.6rem`}}>
													{ShopName('aupay')}</a>
												{/* <a href="#" class={`dropdown-item ${selected === 'other' ? 'is-active' : ''}`} onClick={() => handleItemClick('_other', 'other', option_id)} style={{fontSize:`0.6rem`}}>
													{ShopName('other')}</a> */}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="columns is-multiline is-gapless">
						<div className="column is-half is-vcentered">
							<div class="table-container">
								<table class="table is-bordered has-text-centered">
									<thead>
										<tr id="table1" style={{fontSize:`0.9rem`, backgroundColor: `#EB9DA8`}}>
											<th style={{color: `#ffffff`}}>タイプ</th>
											<th style={{color: `#ffffff`}}>1位</th>
											<th style={{color: `#ffffff`}}>2位</th>
											<th style={{color: `#ffffff`}}>3位</th>
										</tr>
									</thead>
									<tbody>
									{targets.map(target => {
										const type = data.allTypesMilkYaml.edges.find(edge => {	return ( edge.node.cid === target.type )})
										const size = data.allSizesMilkYaml.edges.find(edge => {	return ( edge.node.cid === target.size )})
										const other = target.other
										const sizeStr = 'タイプ' 
										const itemStr = type.node.description
										let rankItems = gerRankItem(filteredContentfulItems, data, type, size, other)
										if(rankItems.lentgh >= 1){
											description += ` ${size.node.description}${type.node.description}-${nameSubbrand(data, rankItems[0])}:${rankItems[0].node.itemList[0].per.toFixed(1)}円 `
										}
										return(
											<tr>
												{ rankItems[0] && (
													<td id = {`${size.node.cid}_${type.node.cid}`} style={{backgroundColor: `#EB9DA8`}}>
														<span style={{fontSize:`0.9rem`}}><strong style={{color: `#ffffff`}}>{type.node.description.replace('粉ミルク',' 粉 ').replace('液体ミルク','液体')}</strong></span>
														<br />
														<span style={{fontSize:`0.6rem`}}><strong style={{color: `#ffffff`}}>{size.node.description}</strong></span>
													</td>
												)}
												{ !rankItems[0] && (
													<td>
													</td>
												)}
												{ rankItems[0] && (
													<td>
														<Link to={linkUrl(data, type, size, rankItems[0], shop_id, option_id, other)}>
															<span style={{fontSize:`0.7rem`, color: `#363636`}}><strong>{nameSubbrand(data, rankItems[0])}</strong></span>
															<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
																<Image filename={ImageFilename(gerBrand(data, rankItems[0]).node.cid, type.node.cid, size.node.cid, gerSubbrand(data, rankItems[0]).node.subbrand_id, rankItems[0].node.itemList[0])} alt={`${gerBrand(data, rankItems[0]).node.description}${gerSubbrand(data, rankItems[0]).description}${type.node.description}タイプ${size.node.description}${sizeStr}の${itemStr}パッケージ画像`}　/>
															</div>
															<span style={{color: `#ff0000`}} className="is-size-6">&nbsp;<strong>{rankItems[0].node.itemList[0].per.toFixed(1)}</strong><span style={{fontSize:`0.7rem`}}> <strong>円</strong>/100ml</span></span>
															<br />
															<span style={{fontSize:`0.7rem`, color: `#0783c2`}}>&nbsp;&nbsp;<strong>{nameShop(data, rankItems[0])}</strong></span>
														</Link>
													</td>
												)}
												{ !rankItems[0] && (
													<td>
													</td>
												)}
												{ rankItems[1] && (
													<td>
														<Link to={linkUrl(data, type, size, rankItems[1], shop_id, option_id, other)}>
															<span style={{fontSize:`0.7rem`, color: `#363636`}}><strong>{nameSubbrand(data, rankItems[1])}</strong></span>
															<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
																<Image filename={ImageFilename(gerBrand(data, rankItems[1]).node.cid, type.node.cid, size.node.cid, gerSubbrand(data, rankItems[1]).node.subbrand_id, rankItems[1].node.itemList[0])} alt={`${gerBrand(data, rankItems[1]).node.description}${gerSubbrand(data, rankItems[1]).description}${type.node.description}タイプ${size.node.description}${sizeStr}の${itemStr}パッケージ画像`}　/>
															</div>
															<span style={{color: `#ff0000`}} className="is-size-6">&nbsp;<strong>{rankItems[1].node.itemList[0].per.toFixed(1)}</strong><span style={{fontSize:`0.7rem`}}> <strong>円</strong>/100ml</span></span>
															<br />
															<span style={{fontSize:`0.7rem`, color: `#0783c2`}}>&nbsp;&nbsp;<strong>{nameShop(data, rankItems[1])}</strong></span>
														</Link>
													</td>
												)}
												{ !rankItems[1] && (
													<td>
													</td>
												)}
												{ rankItems[2] && (
													<td>
														<Link to={linkUrl(data, type, size, rankItems[2], shop_id, option_id, other)}>
															<span style={{fontSize:`0.7rem`, color: `#363636`}}><strong>{nameSubbrand(data, rankItems[2])}</strong></span>
															<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
																<Image filename={ImageFilename(gerBrand(data, rankItems[2]).node.cid, type.node.cid, size.node.cid, gerSubbrand(data, rankItems[2]).node.subbrand_id, rankItems[2].node.itemList[0])} alt={`${gerBrand(data, rankItems[2]).node.description}${gerSubbrand(data, rankItems[2]).description}${type.node.description}タイプ${size.node.description}${sizeStr}の${itemStr}パッケージ画像`}　/>
															</div>
															<span style={{color: `#ff0000`}} className="is-size-6">&nbsp;<strong>{rankItems[2].node.itemList[0].per.toFixed(1)}</strong><span style={{fontSize:`0.7rem`}}> <strong>円</strong>/100ml</span></span>
															<br />
															<span style={{fontSize:`0.7rem`, color: `#0783c2`}}>&nbsp;&nbsp;<strong>{nameShop(data, rankItems[2])}</strong></span>
														</Link>
													</td>
												)}
												{ !rankItems[2] && (
													<td>
													</td>
												)}
											</tr>
										)
									})}
									</tbody>
								</table>
							</div>
						</div>

						<div className="column is-half is-vcentered">
							<div class="table-container">
								<table class="table is-bordered has-text-centered">
									<thead>
										<tr id="table2" style={{fontSize:`0.9rem`, backgroundColor: `#EB9DA8`}}>
											<th style={{color: `#ffffff`}}>タイプ</th>
											<th style={{color: `#ffffff`}}>1位</th>
											<th style={{color: `#ffffff`}}>2位</th>
											<th style={{color: `#ffffff`}}>3位</th>
										</tr>
									</thead>
									<tbody>
									{targets2.map(target => {
										const type = data.allTypesMilkYaml.edges.find(edge => {	return ( edge.node.cid === target.type )})
										const size = data.allSizesMilkYaml.edges.find(edge => {	return ( edge.node.cid === target.size )})
										const other = target.other
										const sizeStr = 'タイプ' 
										const itemStr = type.node.description
										let rankItems = gerRankItem(filteredContentfulItems, data, type, size, other)
										if(rankItems.lentgh >= 1){
											description += ` ${size.node.description}${type.node.description}-${nameSubbrand(data, rankItems[0])}:${rankItems[0].node.itemList[0].per.toFixed(1)}円 `
										}
										return(
											<tr>
												{ rankItems[0] && (
													<td id = {`${size.node.cid}_${type.node.cid}`} style={{backgroundColor: `#EB9DA8`}}>
														<span style={{fontSize:`0.9rem`}}><strong style={{color: `#ffffff`}}>{type.node.description.replace('粉ミルク','粉').replace('液体ミルク','液体')}</strong></span>
														<br />
														<span style={{fontSize:`0.6rem`}}><strong style={{color: `#ffffff`}}>{size.node.description}</strong></span>
													</td>
												)}
												{ !rankItems[0] && (
													<td>
													</td>
												)}
												{ rankItems[0] && (
													<td>
														<Link to={linkUrl(data, type, size, rankItems[0], shop_id, option_id, other)}>
															<span style={{fontSize:`0.7rem`, color: `#363636`}}><strong>{nameSubbrand(data, rankItems[0])}</strong></span>
															<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
																<Image filename={ImageFilename(gerBrand(data, rankItems[0]).node.cid, type.node.cid, size.node.cid, gerSubbrand(data, rankItems[0]).node.subbrand_id, rankItems[0].node.itemList[0])} alt={`${gerBrand(data, rankItems[0]).node.description}${gerSubbrand(data, rankItems[0]).description}${type.node.description}タイプ${size.node.description}${sizeStr}の${itemStr}パッケージ画像`}　/>
															</div>
															<span style={{color: `#ff0000`}} className="is-size-6">&nbsp;<strong>{rankItems[0].node.itemList[0].per.toFixed(1)}</strong><span style={{fontSize:`0.7rem`}}> <strong>円</strong>/100ml</span></span>
															<br />
															<span style={{fontSize:`0.7rem`, color: `#0783c2`}}>&nbsp;&nbsp;<strong>{nameShop(data, rankItems[0])}</strong></span>
														</Link>
													</td>
												)}
												{ !rankItems[0] && (
													<td>
													</td>
												)}
												{ rankItems[1] && (
													<td>
														<Link to={linkUrl(data, type, size, rankItems[1], shop_id, option_id, other)}>
															<span style={{fontSize:`0.7rem`, color: `#363636`}}><strong>{nameSubbrand(data, rankItems[1])}</strong></span>
															<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
																<Image filename={ImageFilename(gerBrand(data, rankItems[1]).node.cid, type.node.cid, size.node.cid, gerSubbrand(data, rankItems[1]).node.subbrand_id, rankItems[1].node.itemList[0])} alt={`${gerBrand(data, rankItems[1]).node.description}${gerSubbrand(data, rankItems[1]).description}${type.node.description}タイプ${size.node.description}${sizeStr}の${itemStr}パッケージ画像`}　/>
															</div>
															<span style={{color: `#ff0000`}} className="is-size-6">&nbsp;<strong>{rankItems[1].node.itemList[0].per.toFixed(1)}</strong><span style={{fontSize:`0.7rem`}}> <strong>円</strong>/100ml</span></span>
															<br />
															<span style={{fontSize:`0.7rem`, color: `#0783c2`}}>&nbsp;&nbsp;<strong>{nameShop(data, rankItems[1])}</strong></span>
														</Link>
													</td>
												)}
												{ !rankItems[1] && (
													<td>
													</td>
												)}
												{ rankItems[2] && (
													<td>
														<Link to={linkUrl(data, type, size, rankItems[2], shop_id, option_id, other)}>
															<span style={{fontSize:`0.7rem`, color: `#363636`}}><strong>{nameSubbrand(data, rankItems[2])}</strong></span>
															<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
																<Image filename={ImageFilename(gerBrand(data, rankItems[2]).node.cid, type.node.cid, size.node.cid, gerSubbrand(data, rankItems[2]).node.subbrand_id, rankItems[2].node.itemList[0])} alt={`${gerBrand(data, rankItems[2]).node.description}${gerSubbrand(data, rankItems[2]).description}${type.node.description}タイプ${size.node.description}${sizeStr}の${itemStr}パッケージ画像`}　/>
															</div>
															<span style={{color: `#ff0000`}} className="is-size-6">&nbsp;<strong>{rankItems[2].node.itemList[0].per.toFixed(1)}</strong><span style={{fontSize:`0.7rem`}}> <strong>円</strong>/100ml</span></span>
															<br />
															<span style={{fontSize:`0.7rem`, color: `#0783c2`}}>&nbsp;&nbsp;<strong>{nameShop(data, rankItems[2])}</strong></span>
														</Link>
													</td>
												)}
												{ !rankItems[2] && (
													<td>
													</td>
												)}
											</tr>
										)
									})}
									</tbody>
								</table>
							</div>
						</div>

					</div>
				</div>
			</>
		<Seo title={title} description={description} canonical={location.pathname} />
  </Layout>
	)
}

function gerRankItem(filteredContentfulItems, data, type, size, other){
	let index = 0
	let rankItems = []
	filteredContentfulItems.map(edge => {
		if(index >= 3){
			return 
		}
		if((!size) || (!type)){
			return;
		}
		if(edge.node.typeId !== type.node.cid || edge.node.sizeId !== size.node.cid) return
		const brand = data.allBrandsMilkYaml.edges.find(n => {
			return n.node.cid === edge.node.brandId
		})
		const subbrand = data.allSubbrandsMilkYaml.edges.find(n => {
			return (n.node.brand_id === edge.node.brandId) && (n.node.subbrand_id === edge.node.subbrandId)
		})
		if(!brand) return
		if(!edge.node.itemList.length) return
		rankItems[index] = edge
		index = index + 1
	})
	return rankItems
}

function gerBrand(data, edge){
	const brand = data.allBrandsMilkYaml.edges.find(n => {
		return n.node.cid === edge.node.brandId
	})
	return brand
}

function gerSubbrand(data, edge){
	const subbrand = data.allSubbrandsMilkYaml.edges.find(n => {
		return (n.node.brand_id === edge.node.brandId) && (n.node.subbrand_id === edge.node.subbrandId)
	})
	return subbrand
}

function nameBrand(data, edge){
	const brand = gerBrand(data, edge)
	let ret = `${brand.node.description}`
	return ret;
}

function nameBrandChange(brandName){
	let ret = brandName.slice()
	if(brandName === 'アンドコンフォート'){
		ret = 'ｱﾝﾄﾞｺﾝﾌｫｰﾄ'
	}
	else if(brandName === 'トップバリュー'){
		ret = 'ﾄｯﾌﾟﾊﾞﾘｭｰ'
	}
	else if(brandName === 'アカチャンホンポ'){
		ret = 'アカホン'
	}
	return ret;
}

function nameSubbrand(data, edge){
	const renames = [
		{brand:'wakodo', 	subbrand:'haihai_810',			name:'はいはい'},
		{brand:'wakodo', 	subbrand:'haihai_300',			name:'はいはい'},
		{brand:'wakodo', 	subbrand:'haihai',					name:'はいはい'},
		{brand:'wakodo', 	subbrand:'gungun_830',			name:'ぐんぐん'},
		{brand:'wakodo', 	subbrand:'gungun_300',			name:'ぐんぐん'},
		{brand:'wakodo', 	subbrand:'gungun',					name:'ぐんぐん'},
		{brand:'meiji', 	subbrand:'hohoemi',					name:'ほほえみ'},
		{brand:'meiji', 	subbrand:'hohoemi_cube_27',	name:'ほほえみ'},
		{brand:'meiji', 	subbrand:'hohoemi_cube_21',	name:'ほほえみ'},
		{brand:'meiji', 	subbrand:'hohoemi_milk_120',name:'ほほえみ'},
		{brand:'meiji', 	subbrand:'hohoemi_milk',		name:'ほほえみ'},
		{brand:'meiji', 	subbrand:'step',						name:'ステップ'},
		{brand:'meiji', 	subbrand:'step_cube_28',		name:'ステップ'},
		{brand:'meiji', 	subbrand:'step_cube_22',		name:'ステップ'},
		{brand:'meiji', 	subbrand:'step_milk_200',		name:'ステップ'},
		{brand:'meiji', 	subbrand:'step_milk',				name:'ステップ'},
		{brand:'morinaga',subbrand:'E_akachan_800',		name:'E赤ちゃん'},
		{brand:'morinaga',subbrand:'E_akachan_300',		name:'E赤ちゃん'},
		{brand:'morinaga',subbrand:'E_akachan',				name:'E赤ちゃん'},
		{brand:'morinaga',subbrand:'E_akachan_eco',		name:'E赤ちゃん'},
		{brand:'morinaga',subbrand:'hagukumi_800',		name:'はぐくみ'},
		{brand:'morinaga',subbrand:'hagukumi_300',		name:'はぐくみ'},
		{brand:'morinaga',subbrand:'hagukumi',				name:'はぐくみ'},
		{brand:'morinaga',subbrand:'hagukumi_eco',		name:'はぐくみ'},
		{brand:'morinaga',subbrand:'hagukumi_milk',		name:'はぐくみ'},
		{brand:'morinaga',subbrand:'chirumiru_800',		name:'チルミル'},
		{brand:'morinaga',subbrand:'chirumiru',				name:'チルミル'},
		{brand:'morinaga',subbrand:'chirumiru_eco',		name:'チルミル'},
		{brand:'snowbean',subbrand:'sukoyaka_800',		name:'すこやかM1'},
		{brand:'snowbean',subbrand:'sukoyaka_300',		name:'すこやかM1'},
		{brand:'snowbean',subbrand:'sukoyaka_13',			name:'すこやかM1'},
		{brand:'snowbean',subbrand:'sukoyaka_6',			name:'すこやかM1'},
		{brand:'snowbean',subbrand:'sukoyaka_milk',		name:'すこやかM1'},
		{brand:'snowbean',subbrand:'tsuyoiko_800',		name:'つよいこ'},
		{brand:'snowbean',subbrand:'tsuyoiko_300',		name:'つよいこ'},
		{brand:'snowbean',subbrand:'tsuyoiko_14',			name:'つよいこ'},
		{brand:'snowmeg',	subbrand:'pure_820',				name:'ぴゅあ'},
		{brand:'snowmeg',	subbrand:'pure',						name:'ぴゅあ'},
		{brand:'snowmeg',	subbrand:'tacchi_830',			name:'たっち'},
		{brand:'snowmeg',	subbrand:'tacchi',					name:'たっち'},
		{brand:'glico',		subbrand:'balance_800',			name:'アイクレオ'},
		{brand:'glico',		subbrand:'balance_320',			name:'アイクレオ'},
		{brand:'glico',		subbrand:'balance',					name:'アイクレオ'},
		{brand:'glico',		subbrand:'growup_820',			name:'アイクレオ'},
		{brand:'glico',		subbrand:'growup',					name:'アイクレオ'},
		{brand:'glico',		subbrand:'akachan',					name:'アイクレオ'},
	]
	const brand = gerBrand(data, edge)
	const subbrand = gerSubbrand(data, edge)
	let ret = `${brand.node.description}${subbrand.node.description}`
	for(let rename of renames){
		if((brand.node.cid === rename.brand) && (subbrand.node.subbrand_id === rename.subbrand)){
			ret = rename.name
			break
		}
	}
	return ret;
}

function nameShop(data, edge){
	const shopId = edge.node.itemList[0].shopId
	const shop = edge.node.itemList[0].shop
	let ret = ''
	if(shopId === 'aeon'){
		ret = 'イオン'
	}
	else if(shopId === 'akachan'){
		ret = 'アカホン'
	}
	else if(shopId === 'amazon'){
		if(shop.includes('定期')){
			ret = 'Amazon定期便'
		}else{
			ret = 'Amazon'
		}
	}
	else if(shopId === 'babiesrus'){
		ret = 'ベビーザらス'
	}
	else if(shopId === 'lohaco'){
		ret = 'LOHACO'
	}
	else if(shopId === 'matsukiyo'){
		ret = 'マツキヨ'
	}
	else if(shopId === 'rakuten'){
		if(shop.includes('ベビー館')){
			ret = '楽天24ベビー館'
		}else{
			ret = '楽天'
		}
	}
	else if(shopId === 'yahoo'){
		ret = 'ヤフショ'
	}
	else if(shopId === 'aupay'){
		ret = 'au PAY'
	}
	else if(shopId === 'nishimatsuya'){
		ret = '西松屋'
	}
	else if(shopId === 'cainz'){
		ret = 'カインズ'
	}
	else if(shopId === 'costco'){
		ret = 'コストコ'
	}
	else if(shopId === 'tsuruha'){
		ret = 'ツルハ'
	}
	else if(shopId === 'kohnan'){
		ret = 'コーナン'
	}
	else if(shopId === 'shimamura'){
		ret = 'しまむら'
	}
	return ret;
}

function linkUrl(data, type, size, edge, shop_id, option_id, other){
	const brand = gerBrand(data, edge)
	const subbrand = gerSubbrand(data, edge)
	let subdir = 'milk'
	let ret = `/${subdir}/${type.node.cid}_${size.node.cid}_${brand.node.cid}_${shop_id}${option_id}`
	if((brand.node.cid === 'ah') || (brand.node.cid === 'ame') || (brand.node.cid === 'topvalue') || (brand.node.cid === 'iris') || (brand.node.cid === 'dacco') || (brand.node.cid === 'matsukiyo')){
		ret = `/${type.node.cid}_${size.node.cid}_${brand.node.cid}_${subbrand.node.subbrand_id}.html`
	}
	return ret;
}

function ShopLink(_shop_id, option_id){
  let href = `/milk${_shop_id}${option_id}.html`
  return href
}

export const query = graphql`
	query {
		allTypesMilkYaml {
			edges {
				node {
					cid
					description
				}
			}
		}
		allSizesMilkYaml {
			edges {
				node {
					cid
					description
				}
			}
		}
		allBrandsMilkYaml {
			edges {
				node {
					cid
					description
				}
			}
		}
		allSubbrandsMilkYaml {
			edges {
				node {
					brand_id
					subbrand_id
					description
					types {
						type_id 
						sizes {
							size_id
							num	
							unit
							use
							pack_unit
						}
					}
				}
			}
		}
		allContentfulItems {
			edges {
				node {
					brandId
					subbrandId
					typeId
					sizeId
					itemList {
						num
						shopId
						shop
						href
						per
						per_org
						price
						price_org
						sendPrice
						souryou
						txt
						point
						star
						review
						reviewUrl
					}
					updatedAt
				}
			}
		}
	}
`
export default TableMilk
